<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item :to="{ path: '/memberlist/memberlist1' }"
        >签到名单</el-breadcrumb-item
      >
      <el-breadcrumb-item :to="{ path: '/memberlist/memberlist2' }"
        >编辑名单</el-breadcrumb-item
      >
    </el-breadcrumb>
    <!--第一个card-->
    <el-card class="right">
      <i class="el-icon-folder"></i>
      <span>名单名称：</span>
      <el-tag type="success">{{ this.listname }}</el-tag>
      <el-tooltip
        class="item"
        effect="dark"
        content="修改名单名称"
        placement="right"
      >
        <i class="el-icon-edit edit" @click="editnamedialogVisible = true"></i>
      </el-tooltip>
    </el-card>
    <!--数据列表-->
    <el-card class="righttable">
      <i class="el-icon-tickets"></i>
      <span>名单成员</span>
      <div style="float:right">
        <el-button type="danger" size="small" @click="deleteall1()"
          >批量删除</el-button
        >
        <el-button type="warning" size="small" @click="add2dialogVisible = true"
          >批量新增</el-button
        >
        <el-button type="success" size="small" @click="add1dialogVisible = true"
          >单个新增</el-button
        >
      </div>
      <el-table
        :data="looktableData"
        border
        @selection-change="handleSelectionChange"
        height="440px"
        stripe
        style="width: 100%;margin-top:20px"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column
                min-width="150"
                align="center">
                <template slot-scope="scope">
                    <div>

                    </div>
                </template>
                </el-table-column> -->
      </el-table>
    </el-card>
    <!--excel新增-->
    <el-dialog
      title="批量新增成员"
      :visible.sync="add2dialogVisible"
      width="50%"
    >
      <a
        @click="downloadxls()"
        style="color: #419eff; border-bottom: 0.5px dotted #419eff"
        >下载模板</a
      >
      <div style="text-align: center">
        <el-upload
          class="upload-demo"
          drag
          action="https://www.xiangqian.club:8001/list/insertMembers/excel"
          :on-change="handleChange"
          :on-success="handleSuccess"
          :headers="headers"
          :data="listDataforexcel"
          name="list"
          ref="upload"
          accept=".xls,.xlsx"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将xls/xlsx文件拖到此处，或<em>点击上传</em>
          </div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="add2dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!--单个新增-->
    <el-dialog
      title="单个新增成员"
      :visible.sync="add1dialogVisible"
      width="30%"
    >
      <el-form
        :model="add1form"
        style="margin-left:30px"
        :rules="rules"
        ref="add1form"
      >
        <el-form-item label="成员名称:" prop="membername">
          <el-input
            v-model="add1form.membername"
            placeholder="成员名称"
            style="width:250px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="canceladd1('add1form')">取 消</el-button>
        <el-button type="primary" @click="add1('add1form')">确 定</el-button>
      </span>
    </el-dialog>
    <!--修改名称-->
    <el-dialog
      title="修改名单名称"
      :visible.sync="editnamedialogVisible"
      width="30%"
    >
      <el-form
        :model="editnameform"
        style="margin-left:30px"
        :rules="rules"
        ref="editnameform"
      >
        <el-form-item label="新的名单名称:" prop="listname">
          <el-input
            v-model="editnameform.listname"
            placeholder="新的名单名称"
            style="width:250px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancleeditname('editnameform')">取 消</el-button>
        <el-button type="primary" @click="editlistname('editnameform')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import qs from 'qs'
export default {
  data() {
    return {
      listname: '',
      looktableData: [],
      //修改名单名称对话框
      editnamedialogVisible: false,
      editnameform: {
        listname: ''
      },
      //单个新增对话框
      add1dialogVisible: false,
      add1form: {
        membername: ''
      },
      //批量新增对话框
      add2dialogVisible: false,
      listId: null,
      headers: {
        Authorization: 'Bearar ' + sessionStorage.getItem('Authorization')
      },
      listDataforexcel: {
        listId: null
      },
      //表单验证信息
      rules: {
        membername: [
          { required: true, message: '请输入成员姓名', trigger: 'change' }
        ],
        listname: [
          { required: true, message: '请输入名单名称', trigger: 'change' }
        ]
      },
      //要删除的成员名称数组
      deletemember: []
    }
  },
  created() {
    this.listId = this.$route.query.listId
  },
  mounted() {
    this.listId = this.$route.query.listId
    this.listname = this.$route.query.listname
    this.listDataforexcel.listId = this.$route.query.listId
    this.getList()
  },
  methods: {
    //下载模板
    downloadxls() {
      window.location.href = process.env.BASE_URL + '名单模板.xls'
    },
    //获取名单成员
    async getList(data1) {
      const { data: res } = await this.$http.get('/list/getMember', {
        params: {
          listId: this.listId
        }
      })
      if (res != null) {
        this.looktableData = res.data
      }
    },
    //取消修改名称
    cancleeditname(formName) {
      this.editnameform.listname = ''
      this.editnamedialogVisible = false
      this.$refs[formName].resetFields()
    },
    //修改名称
    async editlistname(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post(
            '/list/update',
            qs.stringify({
              listId: this.listId,
              listName: this.editnameform.listname
            })
          )
          if (res.code == 200) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.listname = this.editnameform.listname
          } else {
            this.$message.error('提交错误')
          }
          this.editnameform.listname = ''
          this.editnamedialogVisible = false
          this.$refs[formName].resetFields()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //单个新增
    add1(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post(
            '/list/insertMembers/manual',
            qs.stringify({
              listId: this.listId,
              name: this.add1form.membername
            })
          )
          if (res.code == 200) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.getList()
          } else {
            this.$message.error('提交错误')
          }
          this.getList()
          this.add1form.membername = ''
          this.add1dialogVisible = false
          this.$refs[formName].resetFields()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //取消单个新增
    canceladd1(formName) {
      this.add1form.membername = ''
      this.add1dialogVisible = false
      this.$refs[formName].resetFields()
    },
    add2() {
      this.add2dialogVisible = true
    },
    //处理文件成功，再次获取数据
    handleSuccess() {
      this.getList()
    },
    //处理文件上传
    handleChange(file, fileList) {
      console.log('现在的listid', this.listId)
      console.log('现在的listdata', this.listDataforexcel)
      // console.log("文件上传file",file)
      // console.log("文件上传fileList",fileList)
      // var filenamesplit=file.name.split(".")
      // this.listDataforexcel.listName=filenamesplit[0]
      // console.log("现在的name",this.listDataforexcel.listName)
      // console.log("现在的listdata",this.listDataforexcel)
    },
    //批量删除选中数据
    handleSelectionChange(val) {
      console.log('val', val)
      this.deletemember.length = val.length
      for (var i = 0; i < val.length; i++) {
        this.deletemember[i] = val[i].name
      }
      console.log('现在选中的数据', this.deletemember)
    },
    //执行批量删除
    async deleteall1() {
      this.$confirm('此操作将删除选中的成员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          this.$http
            .get(
              '/list/delMembers?listId=' +
                this.listId +
                '&names=' +
                this.deletemember
            )
            .then(res => {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getList()
            })
            .catch(err => {
              console.log('---出错---！' + err.message)
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  }
}
</script>

<style scoped>
.edit {
  margin-left: 5px;
}
.edit:hover {
  cursor: pointer;
}
</style>
